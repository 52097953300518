<template>
    <v-container>
        <v-toolbar flat class="pl-2">
            <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-toolbar-title>Calendario de evaluaciones</v-toolbar-title>
                <v-spacer></v-spacer>
            </template>
            <v-btn @click="prev" class="mr-4" fab small elevation="0">
                <v-icon>
                    mdi-chevron-left
                </v-icon>
            </v-btn>

            <span style="text-transform: uppercase">{{ actualMonth }}</span>

            <v-btn @click="next" class="mx-4" fab small elevation="0">
                <v-icon>
                    mdi-chevron-right
                </v-icon>
            </v-btn>

            <v-select v-model="calendarType" :items="calendarTypes" label="Vista" dense hide-details="" filled rounded
                style="max-width: 150px;" single-line class="rounded-lg"></v-select>

        </v-toolbar>
        <v-row>
            <v-col>
                <v-calendar ref="calendar" v-model="currentMonth" :events="evaluations" :type="calendarType"
                    color="primary"></v-calendar>

                <!--@click:date="openAddDialog"
                    @click:event="handleEventClick"-->
            </v-col>
        </v-row>

        <v-dialog v-model="dialogs.evaluationDate" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ editMode ? 'Edit' : 'Add' }} Evaluation</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="evaluationDateForm" v-model="formIsValid">
                        <v-text-field v-model="evaluationDateObj.date" label="Evaluation Date" readonly
                            :rules="rules.required"></v-text-field>

                        <v-text-field v-model="evaluationDateObj.time" label="Evaluation Time" readonly
                            :rules="rules.required"></v-text-field>

                        <v-text-field v-model="evaluationDateObj.id" label="User ID" readonly></v-text-field>

                        <v-text-field v-model="evaluationDateObj.evaluatedBy" label="Evaluated By"
                            readonly></v-text-field>

                        <v-text-field v-model="evaluationDateObj.evaluatedDate" label="Evaluated Date" readonly
                            v-if="evaluationDateObj.evaluatedBy"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveEvaluationDate">{{ editMode ? 'Update' : 'Save'
                        }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { getFirestore, doc, collection, addDoc, updateDoc, deleteDoc, getDocs } from "firebase/firestore";
import moment from "moment";

export default {
    data() {
        return {
            calendarType: "month",
            calendarTypes: [
                { text: "Mensual", value: "month" },
                { text: "Semanal", value: "week" },
                { text: "Diario", value: "day" },
            ],
            currentMonth: moment().format("YYYY-MM-DD"),
            evaluations: [],
            dialogs: {
                evaluationDate: false,
            },
            evaluationDateObj: {
                date: null,
                time: null,
                id: null,
                evaluatedBy: null,
                evaluatedDate: null,
                doc_id: null,
            },
            editMode: false,
            loading: false,
            formIsValid: false,
            rules: {
                required: [(v) => !!v || "This field is required"],
            },
        };
    },
    methods: {
        async loadEvaluations() {
            const db = getFirestore();
            const evaluationsSnapshot = await getDocs(collection(db, "evaluationsHistory"));
            evaluationsSnapshot.forEach((doc) => {
                let evaluation = doc.data();
                evaluation.doc_id = doc.id;

                // Convert Firestore timestamp to JS Date
                let evaluationDate = new Date(evaluation.date.seconds * 1000);
                let evaluatedDate = evaluation.evaluatedBy ? new Date(evaluation.evaluatedDate.seconds * 1000) : null;

                this.evaluations.push({
                    name: evaluation.id,
                    start: moment(evaluationDate).format("YYYY-MM-DD HH:mm"),
                    // end is required, but we don't have an end time
                    end: moment(evaluationDate).add(1, "hour").format("YYYY-MM-DD HH:mm"),
                    color: evaluation.evaluatedBy ? "green" : "red",
                    ...evaluation,
                    date: evaluationDate,
                    evaluatedDate: evaluatedDate,
                });
            });
        },
        openAddDialog(date) {
            this.evaluationDateObj.date = date;
            this.dialogs.evaluationDate = true;
            this.editMode = false;
        },
        closeDialog() {
            this.dialogs.evaluationDate = false;
            this.evaluationDateObj = {
                date: null,
                time: null,
                id: null,
                evaluatedBy: null,
                evaluatedDate: null,
                doc_id: null,
            };
        },
        async saveEvaluationDate() {
            try {
                if (!this.$refs["evaluationDateForm"].validate()) return;

                let evaluationDateObject = {
                    date: moment(
                        `${this.evaluationDateObj.date} ${this.evaluationDateObj.time}:00`,
                        "YYYY-MM-DD HH:mm:ss"
                    ).toDate(),
                    id: this.evaluationDateObj.id,
                    evaluatedBy: this.evaluationDateObj.evaluatedBy,
                    evaluatedDate: this.evaluationDateObj.evaluatedBy
                        ? moment(this.evaluationDateObj.evaluatedDate, "YYYY-MM-DD HH:mm:ss").toDate()
                        : null,
                };

                this.loading = true;
                const db = getFirestore();

                if (this.evaluationDateObj.doc_id) {
                    await updateDoc(doc(db, "evaluationsHistory", this.evaluationDateObj.doc_id), evaluationDateObject);
                    this.updateLocalEvaluations(evaluationDateObject);
                } else {
                    let result = await addDoc(collection(db, "evaluationsHistory"), evaluationDateObject);
                    evaluationDateObject.doc_id = result.id;
                    this.evaluations.push({
                        name: "Evaluation",
                        start: moment(evaluationDateObject.date).format("YYYY-MM-DD"),
                        color: evaluationDateObject.evaluatedBy ? "green" : "red",
                        ...evaluationDateObject,
                    });
                }

                this.closeDialog();
            } catch (error) {
                console.error("Error saving evaluation: ", error);
            } finally {
                this.loading = false;
            }
        },
        handleEventClick(event) {
            // Load evaluation data into the dialog for editing
            this.evaluationDateObj = {
                ...event,
                date: moment(new Date(event.date.seconds * 1000)).format("YYYY-MM-DD"),
                time: moment(new Date(event.date.seconds * 1000)).format("HH:mm"),
                evaluatedDate: event.evaluatedBy
                    ? moment(new Date(event.evaluatedDate.seconds * 1000)).format("YYYY-MM-DD")
                    : null,
            };
            this.dialogs.evaluationDate = true;
            this.editMode = true;
        },
        async deleteEvaluation(evaluationId) {
            try {
                const db = getFirestore();
                await deleteDoc(doc(db, "evaluationsHistory", evaluationId));
                this.evaluations = this.evaluations.filter((ev) => ev.doc_id !== evaluationId);
            } catch (error) {
                console.error("Error deleting evaluation: ", error);
            }
        },
        updateLocalEvaluations(evaluationDateObject) {
            this.evaluations = this.evaluations.map((item) => {
                if (item.doc_id === this.evaluationDateObj.doc_id) {
                    return {
                        name: "Evaluation",
                        start: moment(evaluationDateObject.date).format("YYYY-MM-DD"),
                        color: evaluationDateObject.evaluatedBy ? "green" : "red",
                        ...evaluationDateObject,
                    };
                }
                return item;
            });
        },
        prev() {
            let mode = this.calendarType;
            let newDate = moment(this.currentMonth).subtract(1, mode).format("YYYY-MM-DD");
            this.currentMonth = newDate;


        },
        next() {
            let mode = this.calendarType;
            let newDate = moment(this.currentMonth).add(1, mode).format("YYYY-MM-DD");
            this.currentMonth = newDate;


        },
    },
    mounted() {
        this.loadEvaluations();
    },
    computed: {
        actualMonth() {
            return moment(this.currentMonth).locale('es').format("MMMM YYYY");
        },
    },
};
</script>

<style scoped>
.v-calendar {
    height: 600px;
}
</style>
